import React, { useEffect, useState } from "react"
import moment from "moment"
import useUserStore from "../../data/userStore"

import AddVenueForm from "./AddVenueForm"

export default function VenueList({
    venues,
    selectedDate,
    createGame,
    date,
    editing = false,
    game = {},
    deleteGame = () => {},
    setCreatingGame,
    chosenVenue,
    chosenTime,
}) {
    const [showAddVenueForm, setShowAddVenueForm] = useState(false)
    const [sortedVenues, setSortedVenues] = useState([])
    const userLocation = useUserStore(state => state.userLocation)
    const [selectedVenue, setSelectedVenue] = useState(
        game.venue || chosenVenue || null
    )
    const [selectedTime, setSelectedTime] = useState(
        game.time || chosenTime || "19:00"
    )

    useEffect(() => {
        if (userLocation) {
            const sorted = venues
                .filter(venue => venue.lat && venue.lat !== "")
                .map(venue => {
                    const distance = calculateDistance(userLocation, {
                        lat: venue.lat,
                        lng: venue.lng,
                    })
                    return { ...venue, distance }
                })
                .sort(
                    (a, b) =>
                        a.distance - b.distance || a.name.localeCompare(b.name)
                )

            setSortedVenues(sorted)
        }
    }, [userLocation, venues])

    const toggleAddVenueForm = () => {
        setShowAddVenueForm(!showAddVenueForm)
    }

    const calculateDistance = (loc1, loc2) => {
        const R = 6371 // Radius of the Earth in km
        const dLat = (loc2.lat - loc1.lat) * (Math.PI / 180)
        const dLng = (loc2.lng - loc1.lng) * (Math.PI / 180)
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(loc1.lat * (Math.PI / 180)) *
                Math.cos(loc2.lat * (Math.PI / 180)) *
                Math.sin(dLng / 2) *
                Math.sin(dLng / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        const distance = R * c // Distance in km
        return distance * 0.621371 // Convert to miles
    }

    const validDate = date => {
        const day = moment(date).format("dddd")
        return day === "Tuesday" || day === "Wednesday" || day === "Thursday"
    }

    return selectedDate !== "no-date" && validDate(date) ? (
        <>
            <button
                className={`add-venue-btn ${showAddVenueForm && "toggled"}`}
                onClick={toggleAddVenueForm}
            >
                {showAddVenueForm ? "Cancel Add Venue" : "Add Venue"}
            </button>
            {showAddVenueForm && (
                <AddVenueForm
                    toggleAddVenueForm={toggleAddVenueForm}
                    selectedDate={
                        selectedDate !== "no-date" ? selectedDate : date
                    }
                />
            )}
            <ul className="live-scoring-list">
                {sortedVenues
                    .filter(
                        venue =>
                            venue.day ===
                            moment(
                                selectedDate !== "no-date" ? selectedDate : date
                            ).format("dddd")
                    )
                    .map(venue => (
                        <li
                            className={`live-scoring-venue-day ${
                                selectedVenue === venue.id && "selected"
                            }`}
                            key={venue.id}
                            onClick={() => setSelectedVenue(venue.id)}
                        >
                            {venue.name} ({venue.distance.toFixed(2)} miles)
                        </li>
                    ))}
                {sortedVenues
                    .filter(
                        venue =>
                            venue.day !==
                            moment(
                                selectedDate !== "no-date" ? selectedDate : date
                            ).format("dddd")
                    )
                    .map(venue => (
                        <li
                            key={venue.id}
                            onClick={() => setSelectedVenue(venue.id)}
                            className={`live-scoring-venue-other ${
                                selectedVenue === venue.id && "selected"
                            }`}
                        >
                            {venue.name} ({venue.distance.toFixed(2)} miles)
                        </li>
                    ))}
            </ul>
            {selectedVenue && (
                <>
                    <p className="selected-venue-time">
                        <input
                            type="time"
                            defaultValue={selectedTime || game.time}
                            onChange={e => setSelectedTime(e.target.value)}
                        />
                    </p>
                    <button
                        className="create-game-btn"
                        onClick={() => createGame(selectedVenue, selectedTime)}
                    >
                        {editing ? "Update Game" : "Create Game"}
                    </button>
                    {editing && (
                        <p className="delete-venue-wrapper">
                            <button
                                className="delete-venue-btn"
                                onClick={() => {
                                    setCreatingGame(null)
                                    deleteGame(game.id)
                                }}
                            >
                                Delete Game & Refund Credit
                            </button>
                        </p>
                    )}
                </>
            )}
        </>
    ) : (
        <h2>Select a Valid Tuesday, Wednesday or Thursday</h2>
    )
}
