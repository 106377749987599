import React, { useEffect, useState } from "react"
import {
    collection,
    doc,
    addDoc,
    getDocs,
    deleteDoc,
    updateDoc,
    Timestamp,
    writeBatch,
} from "firebase/firestore"
import moment from "moment"
import { db } from "../../firebase"
import useScoreStore from "../../data/scoresStore"
import useSeasonStore from "../../data/seasonStore"
import useGameStore from "../../data/gameStore"
import useUserStore from "../../data/userStore"
import useVenueStore from "../../data/venueStore"
import dataService from "../../data/dataService"

export default function ToolsAdmin() {
    const [testGame, setTestGame] = useState(null)
    return (
        <div className="tools-admin">
            <h1>Tools Admin</h1>
            <div className="tools-admin-wrapper">
                <div className="tools-admin-section">
                    <h2>Test Finalizing a Game</h2>
                    <p>
                        We have a Game Report email that we send after each game
                        to members of each team. Let's create a test game with
                        the status of "testing" and then finalize it to see what
                        the email looks like.
                    </p>
                    <button
                        onClick={() => {
                            setTestGame(dataService.createTestGame())
                        }}
                    >
                        Create Test Game
                    </button>
                    <button
                        onClick={() => {
                            dataService.finalizeTestGame(testGame)
                        }}
                    >
                        Finalize Game
                    </button>
                </div>
            </div>
        </div>
    )
}
