import {
    collection,
    doc,
    addDoc,
    getDocs,
    updateDoc,
    QuerySnapshot,
    query,
    where,
    FieldPath,
    deleteDoc,
    documentId,
    limit,
    orderBy,
    startAfter,
} from "firebase/firestore"
import { db } from "../firebase"
import moment from "moment"
import { Timestamp } from "firebase/firestore"
import useSeasonStore from "./seasonStore"

const homepageService = {
    getUpcomingGames: async () => {
        const today = moment().startOf("day")
        const games = []
        const gameCollection = collection(db, "games")
        const gameQuery = query(
            gameCollection,
            where("date", ">=", today.format("YYYY-MM-DD"))
        )
        const gameSnapshot = await getDocs(gameQuery)
        gameSnapshot.forEach(doc => {
            if (doc.data().visibility === "private") {
                return
            }
            games.push({ id: doc.id, ...doc.data() })
        })
        return games
    },
    getRecentGames: async currentRecentGamesPage => {
        const numberOfGames = 6
        const offset = numberOfGames * (currentRecentGamesPage - 1)
        const today = moment().startOf("day")
        const gameCollection = collection(db, "games")

        let gameQuery = query(
            gameCollection,
            where("date", "<=", today.add(3, "days").format("YYYY-MM-DD")),
            where("status", "==", "finalized"),
            orderBy("date", "desc"),
            limit(numberOfGames)
        )

        if (offset > 0) {
            const initialQuery = query(
                gameCollection,
                where("date", "<=", today.format("YYYY-MM-DD")),
                where("status", "==", "finalized"),
                orderBy("date", "desc"),
                limit(offset)
            )
            const initialSnapshot = await getDocs(initialQuery)
            const lastVisible =
                initialSnapshot.docs[initialSnapshot.docs.length - 1]
            if (lastVisible) {
                gameQuery = query(
                    gameCollection,
                    where("date", "<=", today.format("YYYY-MM-DD")),
                    where("status", "==", "finalized"),
                    orderBy("date", "desc"),
                    startAfter(lastVisible),
                    limit(numberOfGames)
                )
            }
        }

        let games = []
        const gameSnapshot = await getDocs(gameQuery)
        gameSnapshot.forEach(doc => {
            games.push({ id: doc.id, ...doc.data() })
        })

        games = await Promise.all(
            games.map(async game => {
                const scores = []
                const scoreCollection = collection(db, "scores")
                const scoreQuery = query(
                    scoreCollection,
                    where("game", "==", game.id),
                    orderBy("score", "desc"),
                    limit(3)
                )
                const scoreSnapshot = await getDocs(scoreQuery)
                scoreSnapshot.forEach(doc => {
                    scores.push(doc.data())
                })
                return { ...game, scores }
            })
        )

        return games
    },
    getTop20TeamsOfCurrentSeason: async () => {
        const teams = []
        const scores = []
        const activeSeason = useSeasonStore.getState().activeSeason
        const scoreCollection = collection(db, "scores")
        if (!activeSeason) {
            return []
        }
        const scoreQuery = query(
            scoreCollection,
            where("season", "==", activeSeason.id)
        )
        // now that we have all of the scores for the current season, let's group them by score.team and then sort by the total so we can return our top 20 teams
        const scoreSnapshot = await getDocs(scoreQuery)
        scoreSnapshot.forEach(doc => {
            const score = doc.data()
            scores.push(score)
        })
        const groupedScores = scores.reduce((acc, score) => {
            if (!acc[score.team]) {
                acc[score.team] = 0
            }
            acc[score.team] += score.score
            return acc
        }, {})
        const sortedScores = Object.keys(groupedScores).sort(
            (a, b) => groupedScores[b] - groupedScores[a]
        )
        const top20Teams = sortedScores.slice(0, 20).map(team => ({
            id: team,
            total: groupedScores[team],
        }))
        return top20Teams
    },
    getTop10TeamsOfSeasonByVenue: async venue_id => {
        const teams = []
        const scores = []
        const activeSeason = useSeasonStore.getState().activeSeason
        const scoreCollection = collection(db, "scores")
        if (!activeSeason) {
            return []
        }
        const scoreQuery = query(
            scoreCollection,
            where("season", "==", activeSeason.id)
        )
        // now that we have all of the scores for the current season, let's group them by score.team and then sort by the total so we can return our top 20 teams
        const scoreSnapshot = await getDocs(scoreQuery)
        scoreSnapshot.forEach(doc => {
            const score = doc.data()
            if (score.venue === venue_id) {
                scores.push(score)
            }
        })
        const groupedScores = scores.reduce((acc, score) => {
            if (!acc[score.team]) {
                acc[score.team] = 0
            }
            acc[score.team] += score.score
            return acc
        }, {})
        const sortedScores = Object.keys(groupedScores).sort(
            (a, b) => groupedScores[b] - groupedScores[a]
        )
        const top20Teams = sortedScores.slice(0, 10).map(team => ({
            id: team,
            total: groupedScores[team],
        }))
        return top20Teams
    },
    getTeamsOfCurrentSeason: async (page = 1, limit = 20) => {
        const teams = []
        const scores = []
        const activeSeason = useSeasonStore.getState().activeSeason
        const scoreCollection = collection(db, "scores")
        if (!activeSeason) {
            return []
        }
        const scoreQuery = query(
            scoreCollection,
            where("season", "==", activeSeason.id)
        )
        const scoreSnapshot = await getDocs(scoreQuery)
        scoreSnapshot.forEach(doc => {
            const score = doc.data()
            scores.push(score)
        })
        const groupedScores = scores.reduce((acc, score) => {
            if (!acc[score.team]) {
                acc[score.team] = 0
            }
            acc[score.team] += score.score
            return acc
        }, {})
        const sortedScores = Object.keys(groupedScores).sort(
            (a, b) => groupedScores[b] - groupedScores[a]
        )
        const paginatedTeams = sortedScores
            .slice((page - 1) * limit, page * limit)
            .map(team => ({
                id: team,
                total: groupedScores[team],
            }))
        return paginatedTeams
    },
}

export default homepageService
