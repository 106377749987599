import React, { useEffect } from "react"

import useCategoryStore from "../../../data/categoryStore"
import useOneGameStore from "../../../data/oneGameStore"
import useTeamStore from "../../../data/teamStore"

export default function CategorySelection({
    view = "slideshow",
    handleCategoryClick,
    handleTeamClick = () => {},
}) {
    const game = JSON.parse(localStorage.getItem("smt-game"))
    const currentSlide = game?.currentSlide
    const currentRound = useOneGameStore(state =>
        state.getCurrentRoundBySlide(currentSlide)
    )

    const lowestScoringTeam =
        game?.rounds?.find(r => r.round === currentRound)?.team || {}
    const matchingTeam = game?.teams.find(t => t.team === lowestScoringTeam)
    const alreadySelectedCategories =
        (game?.rounds && game?.rounds.map(round => round.category)) || []

    useEffect(() => {
        useOneGameStore.getState().refreshGame()
    }, [])

    return (
        <div className="slide category-selection-slide">
            {useCategoryStore
                .getState()
                .categories.filter(c => c.active)
                .map((category, index) => {
                    return (
                        <div
                            key={index}
                            className={`category-selection-item ${category.name
                                .toLowerCase()
                                .replace(" ", "-")}-background-transparent ${
                                alreadySelectedCategories.includes(category.id)
                                    ? "disabled"
                                    : ""
                            }`}
                            onClick={() => {
                                handleCategoryClick(
                                    category.id,
                                    lowestScoringTeam.team
                                )
                            }}
                        >
                            <span className="category-selection-name">
                                {category.name}
                            </span>
                        </div>
                    )
                })}
            <div
                className="category-selection-item selected-team"
                onClick={() => {
                    if (view === "liveScoring") {
                        handleTeamClick()
                    }
                }}
            >
                <span className="selected-team-name">
                    {matchingTeam
                        ? matchingTeam?.displayName
                            ? matchingTeam?.displayName
                            : useTeamStore
                                  .getState()
                                  .teams.find(t => t.id === lowestScoringTeam)
                                  ?.name
                        : "Click to select team"}
                </span>
            </div>
        </div>
    )
}
