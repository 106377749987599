import React, { useEffect } from "react"

import { IoIosClose } from "react-icons/io"

import "./Modal.css"

export default function Modal({ children, title, onClose, className = "" }) {
    useEffect(() => {
        const handleEscapeKey = event => {
            if (event.key === "Escape") {
                event.stopPropagation()
                onClose()
            }
        }

        document.addEventListener("keydown", handleEscapeKey)

        return () => {
            document.removeEventListener("keydown", handleEscapeKey)
        }
    }, [onClose])

    const handleClickOutside = event => {
        if (event.target.classList.contains("modal")) {
            onClose()
        }
    }

    return (
        <div className={`modal ${className}`} onClick={handleClickOutside}>
            <div className="modal-content">
                <header className="modal-header">
                    <h2 className="modal-title">{title}</h2>
                    <button className="modal-close" onClick={onClose}>
                        <IoIosClose />
                    </button>
                </header>
                <main className="modal-main">{children}</main>
            </div>
        </div>
    )
}
