import { Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import dataService from "../data/dataService"
import { useNavigate } from "react-router-dom"
import moment from "moment"

import "./Team.css"
import "./TeamMobile.css"

import { RadarChart } from "./RadarChart"
import { Medal } from "../components/Medal"
import Modal from "../components/Modal/Modal"
import { auth, db } from "../firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import { collection, getDocs, query, where } from "firebase/firestore"
import { IoIosShareAlt } from "react-icons/io"
import { IoCaretForward, IoCaretDown } from "react-icons/io5"
import { MdOpenInFull } from "react-icons/md"
import { FaPencilAlt } from "react-icons/fa" // Import pencil icon

import useCategoryStore from "../data/categoryStore"
import useBadgeStore from "../data/badgeStore"
import Badges from "../components/Teams/Badges"

function ordinal_suffix_of(i) {
    if (i === undefined) return ""
    var j = i % 10,
        k = i % 100
    if (j === 1 && k !== 11) {
        return i + "st"
    }
    if (j === 2 && k !== 12) {
        return i + "nd"
    }
    if (j === 3 && k !== 13) {
        return i + "rd"
    }
    return i + "th"
}

export default function TeamPage({ id = null }) {
    const [user] = useAuthState(auth)
    const [userData, setUserData] = useState({})
    const [scores, setScores] = useState([])
    const [team, setTeam] = useState({})
    const [venues, setVenues] = useState([])
    const [seasons, setSeasons] = useState([])
    const [currentRecentGamesPage, setCurrentRecentGamesPage] = useState(1)
    const [teamStats, setTeamStats] = useState([])

    const [showBadges, setShowBadges] = useState(true)
    const [showMedals, setShowMedals] = useState(true)
    const [showChart, setShowChart] = useState(true)
    const [showScores, setShowScores] = useState(true)
    const [editing, setEditing] = useState(false)
    const [avatarTab, setAvatarTab] = useState("background")
    const [avatar, setAvatar] = useState({})

    const categories = useCategoryStore.getState()

    const navigate = useNavigate()

    /*

    I need to get first get all gamescores for the team


    */
    const [teamData, setTeamData] = useState([])
    const params = useParams()

    useEffect(() => {
        const teamID = id || params.id
        dataService.getData("seasons", () => {}).then(setSeasons)
        dataService.getScoresForTeam(teamID).then(setScores)
        dataService.getData("venues", () => {}).then(setVenues)
        dataService.getTeam(teamID).then(teams => {
            setTeam(teams[0])
        })

        dataService.getTeamCategoryCorrectCount(teamID).then(processTeamStats)
        useBadgeStore.getState().getTeamBadges(teamID)
    }, [params])

    useEffect(() => {
        // dataService.getTeamRanks(scores).then(updatedScores => {
        //     setScores(updatedScores)
        // })
    }, [scores])

    const processTeamStats = stats => {
        // go through each category and make sure it is accounted for in teamStats, if not, add it as an object with the category, correctCount, and incorrectCount both set to 0
        useCategoryStore.getState().categories.forEach(category => {
            if (!stats.find(stat => stat.category === category.id)) {
                stats.push({
                    category: category.id,
                    correctCount: 0,
                    incorrectCount: 0,
                })
            }
        })
        setTeamStats(stats.filter(stat => stat.category !== ""))
    }

    const collectTeamData = () => {
        //  return an array of the percentage of correct answers for each category
        /*

        the numbers need to be in this order
        "History",
                "Science",
                "Music",
                "Movies",
                "Places",
                "Sports",
                "Mystery",
                "Pop Culture",

        */

        const data = []
        const categoryOrder = [
            "History",
            "Science",
            "Music",
            "Movies",
            "Places",
            "Sports",
            "Mystery",
            "Pop Culture",
        ]
        teamStats.forEach(stat => {
            const category = useCategoryStore
                .getState()
                .categories.find(c => c.id === stat.category)
            if (!category || !categoryOrder.includes(category.name)) {
                return
            }
            data.push({
                category: category.name,
                percentage:
                    stat.correctCount === 0 && stat.incorrectCount === 0
                        ? 0
                        : (stat.correctCount /
                              (stat.correctCount + stat.incorrectCount)) *
                          100,
            })
        })
        data.sort((a, b) => {
            return (
                categoryOrder.indexOf(a.category) -
                categoryOrder.indexOf(b.category)
            )
        })
        return data.map(item => (isNaN(item.percentage) ? 0 : item.percentage))
    }

    useEffect(() => {
        dataService.getData("seasons", () => {}).then(setSeasons)
        getUserData()
    }, [user])

    const getUserData = async () => {
        if (user) {
            const q = query(
                collection(db, "users"),
                where("uid", "==", user.uid)
            )
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach(doc => {
                setUserData(doc.data())
            })
        }
    }

    const processWorstOrBestScore = (score, scores, season) => {
        const sortedScores = scores
            .filter(score => score.season === season.id)
            .sort((a, b) => b.date - a.date)
            .slice(0, 10)
            .sort((a, b) => b.score - a.score)

        if (score.score === sortedScores[0].score) {
            return "best"
        } else if (
            score.score === sortedScores[sortedScores.length - 1].score
        ) {
            return "worst"
        } else {
            return ""
        }
    }

    return (
        team &&
        team?.id && (
            <>
                <section>
                    <header className="team-page-header">
                        <div className="team-page-header-left">
                            <div className="team-page-team-avatar"></div>
                            <div className="team-page-team-code">
                                {team.code || "..."}
                            </div>
                        </div>
                        <div className="team-page-header-center">
                            <h1 className="team-name">
                                <span className="team-name-team-text">
                                    {team.name}
                                </span>
                                <span className="team-name-team-membership-status">
                                    {/* check the curent user's teams array to see if this teams id is in it, if so, show "Member of Team" */}
                                    {user &&
                                        userData.teams &&
                                        userData.teams.includes(team.id) &&
                                        "Member of Team"}
                                </span>
                                <Badges
                                    showBadges={showBadges}
                                    setShowBadges={setShowBadges}
                                    team={team}
                                    scores={scores}
                                    teamStats={teamStats}
                                />
                            </h1>
                        </div>
                        <div className="team-page-header-right">
                            {user &&
                                userData.teams &&
                                userData.teams.includes(team.id) && (
                                    <button
                                        className="edit-team-button"
                                        onClick={() => setEditing(!editing)}
                                    >
                                        <FaPencilAlt />
                                    </button>
                                )}

                            <button
                                className="full-screen-button"
                                onClick={() => {
                                    navigate(`/team/${team.id}`)
                                }}
                            >
                                <MdOpenInFull />
                            </button>
                            <button
                                className="share-button"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `${window.location.origin}/team/${team.id}`
                                    )
                                    alert("Team page copied to clipboard!")
                                }}
                            >
                                <IoIosShareAlt className="share-button-icon" />
                            </button>
                        </div>
                    </header>
                    <div className="team-page-content">
                        <div className="team-page-stats-medals team-page-content-section">
                            <h2
                                className="team-page-clickable-header"
                                onClick={() => setShowMedals(!showMedals)}
                            >
                                Medals
                                <span className="team-page-stats-toggle">
                                    {showMedals ? (
                                        <IoCaretForward />
                                    ) : (
                                        <IoCaretDown />
                                    )}
                                </span>
                            </h2>
                            {showMedals && (
                                <div className="medal-area">
                                    <ul className="medal-list generic-medals">
                                        <Medal
                                            name="Games"
                                            value={scores.length}
                                        />
                                        <Medal
                                            name="Score"
                                            value={
                                                scores.reduce(
                                                    (acc, score) =>
                                                        acc + score.score,
                                                    0
                                                )
                                                // teamStats.reduce(
                                                //     (acc, stat) =>
                                                //         acc + stat.correctCount,
                                                //     0
                                                // )
                                            }
                                        />
                                    </ul>
                                    <ul className="medal-list category-medals">
                                        {teamStats
                                            .sort(
                                                (a, b) =>
                                                    b.correctCount -
                                                    a.correctCount
                                            )
                                            .map(stat => (
                                                <Medal
                                                    name={
                                                        categories.categories.find(
                                                            cat =>
                                                                cat.id ===
                                                                stat.category
                                                        ).name
                                                    }
                                                    key={stat.category}
                                                    value={stat.correctCount}
                                                />
                                            ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <section className="team-page-stats team-page-content-section">
                            <h2
                                className="team-page-clickable-header"
                                onClick={() => setShowChart(!showChart)}
                            >
                                Category Accuracy
                                <span className="team-page-stats-toggle">
                                    {showChart ? (
                                        <IoCaretForward />
                                    ) : (
                                        <IoCaretDown />
                                    )}
                                </span>
                            </h2>
                            {showChart && (
                                <RadarChart teamData={collectTeamData()} />
                            )}
                        </section>
                        <div
                            className={`team-page-season-header team-page-content-section`}
                        >
                            <h2
                                className="team-page-clickable-header"
                                onClick={() => setShowScores(!showScores)}
                            >
                                Recent Games
                                <span className="team-page-stats-toggle">
                                    {showScores ? (
                                        <IoCaretForward />
                                    ) : (
                                        <IoCaretDown />
                                    )}
                                </span>
                            </h2>
                            {showScores && (
                                <ul className="teams-page-scores-list">
                                    {scores
                                        .sort((a, b) =>
                                            // use moment to sort by date
                                            moment(b.date.toDate()).diff(
                                                moment(a.date.toDate())
                                            ) > 0
                                                ? 1
                                                : -1
                                        )
                                        .map(
                                            (score, i) =>
                                                i <
                                                    10 *
                                                        currentRecentGamesPage &&
                                                i >=
                                                    10 *
                                                        (currentRecentGamesPage -
                                                            1) && (
                                                    <li
                                                        key={score.id}
                                                        onClick={() =>
                                                            navigate(
                                                                `/game-team/${score.game.replace(
                                                                    "/",
                                                                    ""
                                                                )}/${
                                                                    id ||
                                                                    params.id
                                                                }`
                                                            )
                                                        }
                                                        className={`team-score-page-score rank-${score.rank}`}
                                                    >
                                                        <span className="score-score">
                                                            {score.score}
                                                        </span>
                                                        <span className="score-date-venue-wrapper">
                                                            <span className="score-venue">
                                                                {venues.find(
                                                                    venue =>
                                                                        venue.id ===
                                                                        score.venue
                                                                )?.name ||
                                                                    "Retired Venue"}
                                                            </span>
                                                            <span className="score-date">
                                                                {moment(
                                                                    score.date.toDate()
                                                                ).format(
                                                                    "MMM Do, YYYY"
                                                                )}
                                                            </span>
                                                            <span className="score-rank">
                                                                {ordinal_suffix_of(
                                                                    score.rank
                                                                )}
                                                            </span>
                                                        </span>
                                                    </li>
                                                )
                                        )}
                                </ul>
                            )}
                            <div className="home__recent-game-pagination">
                                <button
                                    className={`home__recent-game-pagination-btn home__recent-game-pagination-prev splash-bg splash-border ${
                                        currentRecentGamesPage === 1 &&
                                        "disabled"
                                    }`}
                                    disabled={currentRecentGamesPage === 1}
                                    onClick={() =>
                                        setCurrentRecentGamesPage(
                                            currentRecentGamesPage - 1
                                        )
                                    }
                                >
                                    Prev
                                </button>
                                <button
                                    className="home__recent-game-pagination-btn home__recent-game-pagination-next splash-bg splash-border"
                                    onClick={() =>
                                        setCurrentRecentGamesPage(
                                            currentRecentGamesPage + 1
                                        )
                                    }
                                    disabled={currentRecentGamesPage >= 100}
                                >
                                    Next
                                </button>
                            </div>
                            <div className="home__recent-game-pagination-details">
                                <span className="home__recent-game-pagination-page">
                                    Page {currentRecentGamesPage}
                                </span>
                            </div>
                        </div>
                        {editing && (
                            <Modal
                                title="Edit Team Avatar"
                                onClose={() => setEditing(false)}
                                className="edit-team-modal"
                            >
                                <div className="edit-team-modal">
                                    <div className="edit-team-avatar-section">
                                        <div className="edit-team-avatar-wrapper">
                                            <div
                                                className={`edit-team-avatar avatar-bg-${avatar.background}`}
                                            ></div>
                                        </div>

                                        <div className="edit-team-avatar-options black-text">
                                            <ul className="edit-team-avatar-options-tabs">
                                                <li
                                                    className={`edit-team-avatar-options-tab ${
                                                        avatarTab ===
                                                        "background"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                    onClick={() =>
                                                        setAvatarTab(
                                                            "background"
                                                        )
                                                    }
                                                >
                                                    Background
                                                </li>
                                                <li
                                                    className={`edit-team-avatar-options-tab ${
                                                        avatarTab === "logo"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                    onClick={() =>
                                                        setAvatarTab("logo")
                                                    }
                                                >
                                                    Logo
                                                </li>
                                                <li
                                                    className={`edit-team-avatar-options-tab ${
                                                        avatarTab === "border"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                    onClick={() =>
                                                        setAvatarTab("border")
                                                    }
                                                >
                                                    Border
                                                </li>
                                            </ul>

                                            <div className="edit-team-avatar-options-content">
                                                {avatarTab === "background" && (
                                                    <div className="edit-team-avatar-options-content-tab">
                                                        <div className="edit-team-avatar-color-options">
                                                            <div className="edit-team-avatar-color-option primary-color">
                                                                <div className="edit-team-avatar-color-square"></div>
                                                                <span className="edit-team-avatar-color-label">
                                                                    Primary
                                                                </span>
                                                            </div>
                                                            <div className="edit-team-avatar-color-option secondary-color">
                                                                <div className="edit-team-avatar-color-square"></div>
                                                                <span className="edit-team-avatar-color-label">
                                                                    Secondary
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <ul className="edit-team-avatar-options-content-tab-list">
                                                            <li
                                                                className="edit-team-avatar-options-content-tab-list-item avatar-bg-wavy"
                                                                onClick={() =>
                                                                    setAvatar({
                                                                        ...avatar,
                                                                        background:
                                                                            "wavy",
                                                                    })
                                                                }
                                                            >
                                                                <span className="edit-team-avatar-options-content-tab-list-item-label">
                                                                    Wavy
                                                                </span>
                                                            </li>
                                                            <li
                                                                className="edit-team-avatar-options-content-tab-list-item avatar-bg-rhombus"
                                                                onClick={() =>
                                                                    setAvatar({
                                                                        ...avatar,
                                                                        background:
                                                                            "rhombus",
                                                                    })
                                                                }
                                                            >
                                                                <span className="edit-team-avatar-options-content-tab-list-item-label">
                                                                    Rhombus
                                                                </span>
                                                            </li>
                                                            <li
                                                                className="edit-team-avatar-options-content-tab-list-item avatar-bg-zigzag"
                                                                onClick={() =>
                                                                    setAvatar({
                                                                        ...avatar,
                                                                        background:
                                                                            "zigzag",
                                                                    })
                                                                }
                                                            >
                                                                <span className="edit-team-avatar-options-content-tab-list-item-label">
                                                                    ZigZag
                                                                </span>
                                                            </li>
                                                            <li
                                                                className="edit-team-avatar-options-content-tab-list-item avatar-bg-zigzag-3d"
                                                                onClick={() =>
                                                                    setAvatar({
                                                                        ...avatar,
                                                                        background:
                                                                            "zigzag-3d",
                                                                    })
                                                                }
                                                            >
                                                                <span className="edit-team-avatar-options-content-tab-list-item-label">
                                                                    ZigZag 3D
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                                {avatarTab === "logo" && (
                                                    <div className="edit-team-avatar-options-content-tab">
                                                        Logo
                                                    </div>
                                                )}
                                                {avatarTab === "border" && (
                                                    <div className="edit-team-avatar-options-content-tab">
                                                        Border
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        )}
                    </div>
                </section>
            </>
        )
    )
}
