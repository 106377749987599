import React, { useEffect, useState } from "react"
import moment from "moment"
import { addDoc, collection } from "firebase/firestore"
import { db } from "../../firebase"
import { Timestamp } from "firebase/firestore"

import "./LiveScoring.css"
import "./LiveScoringMobile.css"
import dataService from "../../data/dataService"
import VenueList from "./VenueList"
import RoundList from "./RoundList"
import TeamsList from "./TeamsList"
import CategoryList from "./CategoryList"
import ScoreboadList from "./ScoreboardList"
import ScoringList from "./ScoringList"
import LiveScoringHeader from "./LiveScoringHeader"
import TeamScoringView from "./TeamScoringView"

import useTemplateStore from "../../data/templateStore"
import useVenueStore from "../../data/venueStore"
import useCategoryStore from "../../data/categoryStore"
import useTeamStore from "../../data/teamStore"
import useOneGameStore from "../../data/oneGameStore"
import useHeaderStore from "../../data/headerStore"
import { Loading } from "../Loading"
import ControlsPanel from "./ControlsPanel"

export default function LiveScoring({
    gameVenue,
    setGameVenue,
    gameID,
    close,
    selectedDate,
    refreshGames,
    numberOfRoundsPerGame,
    questionsPerRound,
    admin = false,
}) {
    const venues = useVenueStore.getState().venues
    const categories = useCategoryStore.getState().categories
    const [currentRound, setCurrentRound] = useState(0)

    const [showControls, setShowControls] = useState(false)

    const [showTeams, setShowTeams] = useState(false)
    const [teamSearch, setTeamSearch] = useState("")
    const [scoringRound, setScoringRound] = useState(false)

    const [showScoreboard, setShowScoreboard] = useState(false)
    const [gameScoreboard, setGameScoreboard] = useState([])
    const [currentTeam, setCurrentTeam] = useState(null)

    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [showAnswer, setShowAnswer] = useState(false)

    const game = useOneGameStore(state => state.game)
    const template = useTemplateStore(state => state.template)

    // const questions = useTemplateStore.getState().questions
    // const gameQuestions = useTemplateStore.getState().gameQuestions
    // const template = useTemplateStore.getState().template

    useEffect(() => {
        if (gameID) {
            useOneGameStore.getState().loadGame(gameID)
        }
    }, [gameID])

    useEffect(() => {
        const interval = setInterval(() => {
            if (gameID) {
                const oldTeams = game?.teams
                useOneGameStore.getState().loadGame(gameID)
                if (
                    oldTeams?.length !==
                    useOneGameStore.getState().game?.teams?.length
                ) {
                    useTeamStore.getState().getTeams()
                }
            }
        }, 30000)

        return () => clearInterval(interval)
    }, [gameID])

    useEffect(() => {
        if (game && game.template) {
            useTemplateStore.getState().getTemplate(game?.template)
        }
    }, [game])

    useEffect(() => {
        if (game) {
            useOneGameStore.getState().updateGame({ showAnswer })
        }
    }, [showAnswer])

    useEffect(() => {
        if (game) {
            useOneGameStore.getState().updateGame({ currentQuestion })
            setShowAnswer(false)
        }
    }, [currentQuestion])

    useEffect(() => {
        useHeaderStore.getState().setShowHeader(false)

        // on component unmount we should reset the header
        return () => {
            useHeaderStore.getState().setShowHeader(true)
        }
    }, [])

    const handledGameOpenedForFirstTime = async () => {
        useOneGameStore.getState().updateGame({ opened: "true" })
    }

    const handleGoBack = () => {
        if (showTeams) {
            setShowTeams(false)
        } else if (showScoreboard) {
            setShowScoreboard(false)
            if (game.status === "finalized" && !admin) {
                close()
            }
        } else if (currentTeam) {
            setCurrentTeam(null)
        } else if (scoringRound) {
            setScoringRound(false)
            setCurrentRound(0)
        } else if (currentRound > 0) {
            setCurrentRound(0)
        } else {
            close()
        }
    }

    const setCategory = (round, category) => {
        useOneGameStore.getState().assignCategory(round, category, 0)
        setCurrentRound(0)
    }

    const addTeamToGame = teamID => {
        useOneGameStore.getState().addTeam(teamID)
    }

    const calculateThirdLine = () => {
        if (game.status === "finalized" && !admin) {
            return "Finalized"
        } else if (currentRound > 0 && !scoringRound) {
            return `Round ${currentRound} - ${
                currentRoundCategory() || "Choose Category"
            }`
        } else if (currentRound > 0 && scoringRound) {
            // When clicking on the scoring-category span, this should show the category list again
            return (
                <span>
                    Round {currentRound} -{" "}
                    <span
                        className="scoring-category"
                        onClick={() => {
                            setScoringRound(false)
                            setCurrentRound(currentRound)
                        }}
                    >
                        {currentRoundCategory() || "Choose Category"}
                    </span>{" "}
                    - Scoring
                </span>
            )
        } else if (showScoreboard) {
            return "Scoreboard"
        } else if (showTeams) {
            return "Teams"
        } else if (selectedDate) {
            return "Choose Venue"
        } else {
            return "Choose Round"
        }
    }

    const setScoreTouched = (team, round) => {
        useOneGameStore.getState().setScoreTouched(team, round)
    }

    const removeTeamFromGame = teamID => {
        if (
            window.confirm(
                `Are you sure you want to remove this team? ${
                    game.status === "finalized"
                        ? "This will remove their score and update their stats."
                        : ""
                }`
            )
        ) {
            useOneGameStore.getState().removeTeam(teamID)
        }
    }

    const processCurrentRound = (round, isCategoryChange = false) => {
        setCurrentRound(round)
        setScoringRound(false)
        if (!isCategoryChange) {
            const gameRound = game.rounds.find(r => r.round === round)
            if (gameRound.category !== "") {
                setScoringRound(true)
            }
        }
    }

    const setScoring = (team, round, question, correct) => {
        useOneGameStore.getState().scoreRound(team, round, question, correct)
    }

    const currentRoundHasCategory = () => {
        return game.rounds.find(r => r.round === currentRound).category !== ""
    }

    const currentRoundCategory = () => {
        const categoryName = categories.find(
            cat =>
                cat.id ===
                game.rounds.find(r => r.round === currentRound)?.category
        )?.name

        if (categoryName === "Mystery") {
            return `${categoryName}: ${template.subCat}`
        }

        return categoryName
    }

    const finalizeScores = () => {
        useOneGameStore.getState().finalizeScores()
        refreshGames()
        close()
    }

    const markAllScoresTouched = () => {
        useOneGameStore.getState().markAllScoresTouched()
    }

    return game ? (
        <section className={`live-scoring live-scoring-admin-${admin}`}>
            <LiveScoringHeader
                handleGoBack={close}
                game={game}
                gameVenue={gameVenue}
                setGameVenue={setGameVenue}
                selectedDate={selectedDate}
                gameTeams={game.teams}
                showTeams={showTeams}
                setShowTeams={setShowTeams}
                setCurrentRound={setCurrentRound}
                setShowScoreboard={setShowScoreboard}
                calculateThirdLine={calculateThirdLine}
                setCurrentTeam={setCurrentTeam}
                admin={admin}
                handledGameOpenedForFirstTime={handledGameOpenedForFirstTime}
                markAllScoresTouched={markAllScoresTouched}
            />
            <main>
                <>
                    <div
                        className={`live-scoring-main-left ${
                            showControls && "hide"
                        }`}
                    >
                        {game &&
                            !selectedDate &&
                            currentRound === 0 &&
                            !showScoreboard &&
                            !showTeams &&
                            (game.status !== "finalized" || admin) && (
                                <RoundList
                                    processCurrentRound={processCurrentRound}
                                    setCategory={setCategory} // Pass setCategory
                                />
                            )}

                        {game &&
                            !selectedDate &&
                            currentRound === 0 &&
                            !showScoreboard &&
                            showTeams &&
                            (game.status !== "finalized" || admin) && (
                                <TeamsList
                                    teamSearch={teamSearch}
                                    setTeamSearch={setTeamSearch}
                                    game={game}
                                    gameTeams={game.teams}
                                    addTeamToGame={addTeamToGame}
                                    removeTeamFromGame={removeTeamFromGame}
                                    admin={admin}
                                    gameScoreboard={gameScoreboard}
                                    setShowTeams={setShowTeams}
                                    setShowControls={setShowControls}
                                />
                            )}

                        {game &&
                            !selectedDate &&
                            currentRound > 0 &&
                            !scoringRound &&
                            !showScoreboard &&
                            !showTeams &&
                            (game.status !== "finalized" || admin) && (
                                <CategoryList
                                    currentRoundCategory={currentRoundCategory}
                                    currentRoundHasCategory={
                                        currentRoundHasCategory
                                    }
                                    currentRound={currentRound}
                                    categories={categories}
                                    gameRounds={game.rounds}
                                    setCategory={setCategory}
                                    setScoringRound={setScoringRound}
                                    setCurrentRound={setCurrentRound}
                                    admin={admin}
                                />
                            )}

                        {game &&
                            !selectedDate &&
                            currentRound > 0 &&
                            scoringRound &&
                            !showScoreboard &&
                            !showTeams &&
                            !currentTeam &&
                            (game.status !== "finalized" || admin) && (
                                <ScoringList
                                    currentRound={currentRound}
                                    currentRoundCategory={currentRoundCategory}
                                    setScoring={setScoring}
                                    setCurrentRound={setCurrentRound}
                                    setScoringRound={setScoringRound}
                                    currentTeam={currentTeam}
                                    setCurrentTeam={setCurrentTeam}
                                    numberOfRoundsPerGame={
                                        numberOfRoundsPerGame
                                    }
                                    questionsPerRound={questionsPerRound}
                                    admin={admin}
                                />
                            )}

                        {game &&
                            !selectedDate &&
                            currentRound > 0 &&
                            scoringRound &&
                            !showScoreboard &&
                            !showTeams &&
                            currentTeam && (
                                <TeamScoringView
                                    currentTeam={currentTeam}
                                    setCurrentTeam={setCurrentTeam}
                                    currentRound={currentRound}
                                    setScoring={setScoring}
                                    numberOfRoundsPerGame={
                                        numberOfRoundsPerGame
                                    }
                                    questionsPerRound={questionsPerRound}
                                    setScoreTouched={setScoreTouched}
                                    admin={admin}
                                />
                            )}

                        {game && !selectedDate && showScoreboard && (
                            <ScoreboadList gameScoreboard={gameScoreboard} />
                        )}
                    </div>
                    <ControlsPanel
                        template={template}
                        showControls={showControls}
                        setShowControls={setShowControls}
                        game={game}
                        setCurrentQuestion={setCurrentQuestion}
                        currentQuestion={currentQuestion}
                        showAnswer={showAnswer}
                        setShowAnswer={setShowAnswer}
                        admin={admin}
                        showTeams={showTeams}
                        setShowTeams={setShowTeams}
                        setCurrentTeam={setCurrentTeam}
                        setShowScoreboard={setShowScoreboard}
                        setCurrentRound={setCurrentRound}
                        currentRound={currentRound}
                        finalizeScores={finalizeScores}
                        processCurrentRound={processCurrentRound}
                        setScoringRound={setScoringRound}
                        handledGameOpenedForFirstTime={
                            handledGameOpenedForFirstTime
                        }
                    />
                </>
            </main>
            <footer>
                <button
                    onClick={() => {
                        setShowControls(!showControls)
                        setShowTeams(false)
                    }}
                    className={`${
                        showControls
                            ? "show-controls"
                            : "show-controls show-scoring"
                    }`}
                >
                    {showControls ? "Hide Controls" : "Show Controls"}
                </button>
            </footer>
        </section>
    ) : (
        <Loading />
    )
}
