import React, { useState, useEffect, useContext } from "react"
import QRCode from "qrcode.react"
import { useParams, useNavigate, Link } from "react-router-dom"
import dataService from "../../data/dataService"
import useGameStore from "../../data/gameStore"
import useOneGameStore from "../../data/oneGameStore"
import useCategoryStore from "../../data/categoryStore"
import useTeamStore from "../../data/teamStore"
import useVenueStore from "../../data/venueStore"
import GameHeader from "./GameHeader"
import Scoreboard from "./Scoreboard"
import AnswerSheet from "./AnswerSheet"
import useGameStatus from "./useGameStatus"
import "./Game.css"
import { rankings } from "../../utils/dateUtils"

import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../../firebase"
import Slideshow from "../../components/Game/Slideshow"
import Search from "../../components/Layout/Search"
import Modal from "../../components/Modal/Modal"
import TeamPage from "../../pages/Team"
import footerContext from "../../data/footerContext"
import useUserStore from "../../data/userStore"
export default function Game({
    id = "0",
    type = "game",
    presentation = "game",
}) {
    const [user] = useAuthState(auth)
    const { gameid, teamid, venue, templateid } = useParams()
    const navigate = useNavigate()
    const game = useGameStore(state => state.game)
    const oneGame = useOneGameStore(state => state.game)
    const [scores, setScores] = useState([])
    const [loading, setLoading] = useState(true)
    const [hasRounds, setHasRounds] = useState(false)
    const [activeTeam, setActiveTeam] = useState(null)
    const [scoreCard, setScoreCard] = useState(null)
    const { legacy, live, upcoming } = useGameStatus(game, venue)
    const [gameVenue, setGameVenue] = useState(null)
    const [currentTeam, setCurrentTeam] = React.useState(null)
    const [addTeam, setAddTeam] = React.useState()
    const [addTeamMate, setAddTeamMate] = React.useState(false)
    const [joinTeamMate, setJoinTeamMate] = React.useState(false)
    const [scoreCardTeamMates, setScoreCardTeamMates] = React.useState([])
    const teams = useTeamStore(state => state.teams)
    const [createTeam, setCreateTeam] = React.useState(false)
    const [newTeam, setNewTeam] = React.useState("")

    const { currentMobileTab, setCurrentMobileTab } = useContext(footerContext)

    useEffect(() => {
        if (gameid !== undefined || (id !== "0" && type === "game")) {
            let gameId = gameid || id
            useGameStore.getState().getGame(gameId, boolRounds => {
                setLoading(false)
                setScores(useGameStore.getState().scores)
                setHasRounds(boolRounds > 0)
                setGameVenue(
                    useVenueStore
                        .getState()
                        .venues.find(
                            v => v.id === useGameStore.getState().game?.venue
                        )
                )
            })
            useOneGameStore.getState().loadGame(gameId)
            const interval = setInterval(() => {
                let gameId = gameid || id
                const oldTeams = useGameStore.getState().game?.teams
                useGameStore.getState().getGame(gameId, boolRounds => {
                    setLoading(false)
                    setScores(useGameStore.getState().scores)
                    setHasRounds(boolRounds > 0)
                    if (
                        oldTeams.length !==
                        useGameStore.getState().game?.teams.length
                    ) {
                        useTeamStore.getState().getTeams()
                    }
                    setGameVenue(
                        useVenueStore
                            .getState()
                            .venues.find(
                                v =>
                                    v.id === useGameStore.getState().game?.venue
                            )
                    )
                })
                useOneGameStore.getState().loadGame(gameId)
            }, 60000)
            return () => {
                clearInterval(interval)
            }
        }
    }, [gameid, id, type])

    useEffect(() => {
        if (teamid !== undefined) {
            setActiveTeam(teamid)
        }
    }, [teamid])

    useEffect(() => {
        if (venue !== undefined || (id !== "0" && type === "venue")) {
            let venueId = venue || id
            setGameVenue(
                useVenueStore.getState().venues.find(v => v.id === venueId)
            )
            setLoading(false)
        }
    }, [venue, id, type])

    // create a useEffect to clear the game state when the component is unmounted
    useEffect(() => {
        setCurrentMobileTab("")
        return () => {
            useGameStore.getState().resetGame()
        }
    }, [])

    useEffect(() => {
        if (addTeam && !user) {
            navigate(`/login?redirectTo=${window.location.pathname}`)
        }
        if (addTeam && user) {
            // we need to see if this user is already a member of any teams in the current game if they are we need to set addTeam to false to prevent the modal from popping up over and over again
        }
    }, [addTeam, user, navigate])

    useEffect(() => {
        if (currentTeam && user) {
            const teamExistsInGame = game.teams?.some(
                team => team.team === currentTeam.id
            )
            if (teamExistsInGame) {
                useOneGameStore.getState().addTeamMate(currentTeam.id, user.uid)
                useTeamStore.getState().handleJoinTeam(currentTeam.id, user.uid)
                setJoinTeamMate(false)
            } else {
                useOneGameStore
                    .getState()
                    .addTeam(currentTeam.id, currentTeam.displayName, user.uid)
                useTeamStore.getState().handleJoinTeam(currentTeam.id, user.uid)
                setAddTeam(false)
                setAddTeamMate(currentTeam.id)
            }
            setCurrentTeam(null)
            setTimeout(() => {
                useGameStore.getState().getGame(game.id, () => {
                    setScores(useGameStore.getState().scores)
                })
            }, 500)
        }
    }, [currentTeam, user, navigate, game?.id])

    useEffect(() => {
        if (joinTeamMate) {
            if (user) {
                useOneGameStore.getState().addTeamMate(joinTeamMate, user.uid)
                useTeamStore.getState().handleJoinTeam(joinTeamMate, user.uid)
                setJoinTeamMate(false)
                useGameStore.getState().getGame(game.id, () => {
                    setScores(useGameStore.getState().scores)
                })
            } else {
                navigate(`/login?redirectTo=${window.location.pathname}`)
            }
        }
    }, [joinTeamMate, user, navigate, game?.id])

    useEffect(() => {
        if (!user && !loading && presentation === "liveuser") {
            navigate(`/login?redirectTo=${window.location.pathname}`)
        }
        if (presentation === "liveuser" || presentation === "liveteammate") {
            localStorage.setItem("ttl-game-url", window.location.pathname)
        }
    }, [user, loading, navigate, presentation])

    const isUserMemberOfAnyTeam = () => {
        return oneGame?.teams?.some(t => t.members?.includes(user?.uid))
    }

    const isMemberOfThisTeam = teamId => {
        return oneGame?.teams
            ?.find(t => t.team === teamId)
            ?.members?.includes(user?.uid)
    }

    const fetchMemberDetails = async () => {
        const details = await useOneGameStore
            .getState()
            .findMemberDetails(scoreCard)
        setScoreCardTeamMates(details)
    }

    useEffect(() => {
        if (scoreCard) {
            fetchMemberDetails()
        }
    }, [scoreCard])

    const isUserAtVenue = (loc1, loc2, dis = false) => {
        if (!loc1 || !loc2) {
            return 0
        }
        const R = 6371 // Radius of the Earth in km
        const dLat = (loc2.lat - loc1.lat) * (Math.PI / 180)
        const dLng = (loc2.lng - loc1.lng) * (Math.PI / 180)
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(loc1.lat * (Math.PI / 180)) *
                Math.cos(loc2.lat * (Math.PI / 180)) *
                Math.sin(dLng / 2) *
                Math.sin(dLng / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        const distance = R * c // Distance in km
        const distanceMiles = distance * 0.621371 // Convert to miles
        return dis ? distanceMiles : distanceMiles < 20000
    }

    // if the current logged in user is the host of the game, we want to render a different div

    return (game || legacy || live || upcoming) && !loading ? (
        <section
            className={`game-page ${legacy ? "legacy" : "non-legacy"} ${
                live ? "live" : "not-live"
            } presentation-${presentation} ${
                game?.user === user?.uid ? "host-is-viewing" : "not-host"
            }`}
        >
            {presentation === "slideshow" ? (
                <Slideshow />
            ) : (
                <>
                    <GameHeader
                        game={oneGame}
                        gameVenue={gameVenue}
                        setAddTeam={setAddTeam}
                        user={user}
                        presentation={presentation}
                        setAddTeamMate={setAddTeamMate}
                        setJoinTeamMate={setJoinTeamMate}
                        templateid={templateid}
                        teamid={teamid}
                        isUserAtVenue={isUserAtVenue(
                            useUserStore.getState().userLocation,
                            gameVenue
                        )}
                        distance={isUserAtVenue(
                            useUserStore.getState().userLocation,
                            gameVenue,
                            true
                        )}
                    />
                    <div className="game-body">
                        <Scoreboard
                            scores={scores}
                            legacy={legacy}
                            activeTeam={activeTeam}
                            setScoreCard={setScoreCard}
                            hasRounds={hasRounds}
                        />
                        {!addTeam &&
                            isUserAtVenue(
                                useUserStore.getState().userLocation,
                                gameVenue
                            ) &&
                            presentation === "liveuser" &&
                            !isUserMemberOfAnyTeam() && (
                                <p className="game-page__add-team">
                                    <button
                                        className="add-team"
                                        onClick={() => setAddTeam(true)}
                                    >
                                        Add Team to Game {gameVenue.distance}
                                    </button>
                                </p>
                            )}
                    </div>
                </>
            )}
            <footer className="game-page-footer">
                {/* {game && game.status !== "finalized" && (
                    <button>Join Game</button>
                )} */}
            </footer>
            {scoreCard &&
                (isMemberOfThisTeam(scoreCard) || !isUserMemberOfAnyTeam()) && (
                    <Modal
                        title={`${
                            oneGame?.teams?.find(
                                team => team.team === scoreCard
                            )?.displayName ||
                            teams?.find(team => team.id === scoreCard)?.name
                        } - Score Card`}
                        onClose={() => setScoreCard(null)}
                        className="game-team-modal"
                    >
                        {!isUserMemberOfAnyTeam() &&
                            isUserAtVenue(
                                useUserStore.getState().userLocation,
                                gameVenue
                            ) &&
                            game.status !== "finalized" && (
                                <button
                                    className="join-team-as-member"
                                    onClick={() => {
                                        setCurrentTeam({ id: scoreCard })
                                        setTimeout(
                                            () => fetchMemberDetails(),
                                            500
                                        )
                                    }}
                                >
                                    Join Team as Member
                                </button>
                            )}
                        {isMemberOfThisTeam(scoreCard) &&
                            game.status !== "finalized" && (
                                <>
                                    <button
                                        className="leave-team-as-member"
                                        onClick={() => {
                                            useOneGameStore
                                                .getState()
                                                .removeTeamMate(
                                                    scoreCard,
                                                    user.uid
                                                )
                                            setScoreCard(null)
                                        }}
                                    >
                                        Leave Team as Member
                                    </button>
                                    <button
                                        className="edit-team-display-name"
                                        onClick={() => {
                                            const newDisplayName = prompt(
                                                "Enter new team display name",
                                                oneGame?.teams.find(
                                                    team =>
                                                        team.team === scoreCard
                                                )?.displayName ||
                                                    teams.find(
                                                        team =>
                                                            team.id ===
                                                            scoreCard
                                                    )?.name
                                            )
                                            if (newDisplayName) {
                                                useOneGameStore
                                                    .getState()
                                                    .changeTeamDisplayName(
                                                        scoreCard,
                                                        newDisplayName
                                                    )
                                            }
                                        }}
                                        disabled={game?.started}
                                    >
                                        {game?.started
                                            ? "Name Locked"
                                            : "Edit Display Name"}
                                    </button>
                                    <div className="team-members">
                                        <h2>Team Members</h2>
                                        {scoreCardTeamMates.map(member => (
                                            <div
                                                key={member.uid}
                                                className={`team-member ${
                                                    member.uid === user?.uid
                                                        ? "me"
                                                        : ""
                                                }`}
                                                onClick={() => {
                                                    navigate(
                                                        `/user/${member.uid}`
                                                    )
                                                }}
                                            >
                                                <span className="team-member-name">
                                                    {member.name
                                                        ? member.name
                                                        : member.email}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        <AnswerSheet
                            scoreCard={scoreCard}
                            onClose={() => setScoreCard(null)}
                            legacy={legacy}
                            isMember={isMemberOfThisTeam(scoreCard)}
                        />
                    </Modal>
                )}
            {scoreCard &&
                isUserMemberOfAnyTeam() &&
                !isMemberOfThisTeam(scoreCard) && (
                    <Modal
                        title="Team Details"
                        onClose={() => setScoreCard(null)}
                        className="team-modal small-header"
                    >
                        <TeamPage id={scoreCard} />
                    </Modal>
                )}
            {addTeam && (
                <Modal
                    title={`Joining Trivia at ${gameVenue?.name}, select your team!`}
                    onClose={() => setAddTeam(false)}
                    className="add-team-modal"
                >
                    {/* <button
                        className="add-new-team"
                        onClick={() => setCreateTeam(true)}
                    >
                        Create New Team
                    </button> */}
                    <h2 className="add-team-title">
                        Joining Game - {gameVenue?.name}
                    </h2>
                    <p className="add-team-description">
                        Search for your team, or create a new team to join the
                        game. Previously used teams will show automatically
                        below the search box.
                    </p>
                    {createTeam && (
                        <div className="create-new-team">
                            <h2>Create New Team</h2>
                            <input
                                type="text"
                                value={newTeam}
                                onChange={e => setNewTeam(e.target.value)}
                                placeholder="Team Name"
                                autoFocus={createTeam}
                            />
                            <div className="create-new-team-buttons">
                                <button
                                    className="cancel-create-new-team"
                                    onClick={() => setCreateTeam(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={async () => {
                                        if (newTeam) {
                                            const newTeamID = await useTeamStore
                                                .getState()
                                                .createNewTeam(newTeam)
                                            if (!newTeamID) {
                                                alert(
                                                    "Team name already exists"
                                                )
                                                return
                                            }
                                            setCurrentTeam({ id: newTeamID })
                                            setNewTeam("")
                                        }
                                    }}
                                >
                                    Create Team
                                </button>
                            </div>
                        </div>
                    )}
                    <Search
                        currentTeam={currentTeam}
                        setCurrentTeam={setCurrentTeam}
                        teamsToExclude={[]}
                        gameTeams={game.teams}
                        user={user}
                    />
                </Modal>
            )}
            {addTeamMate !== false && (
                <Modal
                    title={`Gotcha, now share this QR code with your teammates to join ${
                        game?.teams?.find(t => t.team === addTeamMate)
                            ?.displayName !== ""
                            ? game?.teams?.find(t => t.team === addTeamMate)
                                  ?.displayName
                            : useTeamStore
                                  .getState()
                                  .teams.find(t => t.id === addTeamMate)?.name
                    } for tonight's game!`}
                    onClose={() => setAddTeamMate(false)}
                    className="add-teammate-modal"
                >
                    <QRCode
                        value={`${window.location.origin}/live-game-teammate/${game.id}/${addTeamMate}`}
                        size={400}
                    />
                    <Link
                        to={`/live-game-teammate/${game.id}/${addTeamMate}`}
                        target="_blank"
                    >
                        .
                    </Link>
                </Modal>
            )}
        </section>
    ) : (
        <section className="game-page">
            <h1>{loading ? "Loading Game...." : "Apologies"}</h1>
            {!loading && (
                <div>
                    <h2>Game not found</h2>
                    <a
                        className="mb-10"
                        href="/back"
                        onClick={e => {
                            e.preventDefault()
                            navigate(-1)
                        }}
                    >
                        Back
                    </a>
                </div>
            )}
        </section>
    )
}
