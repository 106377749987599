import create from "zustand"
import { db } from "../firebase" // Adjust the path according to your project structure
import { gameOptions } from "../utils/dateUtils"
import {
    doc,
    getDoc,
    updateDoc,
    addDoc,
    collection,
    query,
    where,
    getDocs,
} from "firebase/firestore"

import moment from "moment"

import useTeamStore from "./teamStore"

const useOneGameStore = create((set, get) => ({
    game: null,
    gameRounds: [],
    gameTeams: [],
    gameScores: [],
    scoreboard: [],
    template: null,
    loading: false,
    error: null,
    questionsPerRound: gameOptions.questionsPerRound[0],
    slides: {
        0: "Welcome",
        1: "HowItWorks",
        2: "Rules",
        3: "Signup",
        4: "Start",
        // round 1
        5: "CategorySelection",
        6: "Category",
        7: "Question",
        8: "Question",
        9: "Question",
        10: "Question",
        11: "Question",
        12: "Question",
        13: "Question",
        14: "Question",
        15: "Rotating",
        16: "Answers",
        17: "Scoreboard",
        // round 2
        18: "CategorySelection",
        19: "Category",
        20: "Question",
        21: "Question",
        22: "Question",
        23: "Question",
        24: "Question",
        25: "Question",
        26: "Question",
        27: "Question",
        28: "Rotating",
        29: "Answers",
        30: "Scoreboard",
        // round 3
        31: "CategorySelection",
        32: "Category",
        33: "Question",
        34: "Question",
        35: "Question",
        36: "Question",
        37: "Question",
        38: "Question",
        39: "Question",
        40: "Question",
        41: "Rotating",
        42: "Answers",
        43: "Scoreboard",
        // round 4
        44: "CategorySelection",
        45: "Category",
        46: "Question",
        47: "Question",
        48: "Question",
        49: "Question",
        50: "Question",
        51: "Question",
        52: "Question",
        53: "Question",
        54: "Rotating",
        55: "Answers",
        56: "Scoreboard",
        // round 5
        57: "CategorySelection",
        58: "Category",
        59: "Question",
        60: "Question",
        61: "Question",
        62: "Question",
        63: "Question",
        64: "Question",
        65: "Question",
        66: "Question",
        67: "Rotating",
        68: "Answers",
        69: "Scoreboard",
        // round 6
        70: "CategorySelection",
        71: "Category",
        72: "Question",
        73: "Question",
        74: "Question",
        75: "Question",
        76: "Question",
        77: "Question",
        78: "Question",
        79: "Question",
        80: "Rotating",
        81: "Answers",
        82: "Scoreboard",
        // round 7
        83: "CategorySelection",
        84: "Category",
        85: "Question",
        86: "Question",
        87: "Question",
        88: "Question",
        89: "Question",
        90: "Question",
        91: "Question",
        92: "Question",
        93: "Rotating",
        94: "Answers",
        95: "Scoreboard",
        // finale
        96: "Scoreboard",
        97: "End",
    },

    lastSlide: () => {
        const slides = Object.keys(get().slides).map(Number)
        return Math.max(...slides)
    },

    rounds: [
        {
            slide: 6,
            round: 1,
        },
        {
            slide: 19,
            round: 2,
        },
        {
            slide: 32,
            round: 3,
        },
        {
            slide: 45,
            round: 4,
        },
        {
            slide: 58,
            round: 5,
        },
        {
            slide: 71,
            round: 6,
        },
        {
            slide: 84,
            round: 7,
        },
    ],

    isAnswerSlide: () => {
        const currentSlide = get().game?.currentSlide || 0
        return get().slides[currentSlide] === "Answers"
    },

    isQuestionSlide: () => {
        const currentSlide = get().game?.currentSlide || 0
        return get().slides[currentSlide] === "Question"
    },

    getCurrentQuestion: () => {
        const currentSlide = get().game?.currentSlide || 0
        const currentRound = get().getCurrentRound()
        return (
            currentSlide -
            get().rounds.find(r => r.round === currentRound)?.slide +
            1
        )
    },

    isCategorySlide: () => {
        const currentSlide = get().game?.currentSlide || 0
        return get().slides[currentSlide] === "Category"
    },

    isCategorySelectionSlide: () => {
        const currentSlide = get().game?.currentSlide || 0
        return get().slides[currentSlide] === "CategorySelection"
    },

    isRotatingSlide: () => {
        const currentSlide = get().game?.currentSlide || 0
        return get().slides[currentSlide] === "Rotating"
    },

    isScoreboardSlide: () => {
        const currentSlide = get().game?.currentSlide || 0
        return get().slides[currentSlide] === "Scoreboard"
    },

    isFinalScoreboardSlide: () => {
        const currentSlide = get().game?.currentSlide || 0
        return currentSlide === 95
    },

    isEndSlide: () => {
        const currentSlide = get().game?.currentSlide || 0
        return get().slides[currentSlide] === "End"
    },

    buildSlideDropdown: categories => {
        const slides = Object.keys(get().slides).map(Number)
        // remove any slides that are a Question slide

        return (
            slides
                .map(slide => {
                    let label = get().slides[slide]
                    if (label === "CategorySelection") {
                        label =
                            "Select Category for Round " +
                            get().rounds.find(
                                round => round.slide === slide + 1
                            )?.round
                    }
                    if (label === "Category") {
                        const currentRoundNumber = get().rounds.find(
                            round => round.slide === slide
                        )?.round

                        const gameRoundCategory = get().game?.rounds?.find(
                            round => round.round === currentRoundNumber
                        )?.category

                        const gameRoundCategoryName =
                            categories.find(
                                category => category.id === gameRoundCategory
                            )?.name || ""

                        label =
                            "---- Round " +
                            currentRoundNumber +
                            ": " +
                            gameRoundCategoryName +
                            " ----"
                    }

                    if (label === "Rotating") {
                        label = "Rotating Questions"
                    }

                    if (label === "Scoreboard") {
                        label = slide !== 96 ? "Scoreboard" : "Final Scoreboard"
                    }

                    if (label === "Question") {
                        // find the closest round slide that is less than the current slide and use that round number
                        const currentRound = get().rounds.find(
                            (r, index, arr) =>
                                r.slide <= slide &&
                                (arr[index + 1]?.slide > slide ||
                                    index === arr.length - 1)
                        )?.round
                        label = `Question ${
                            slide -
                            get().rounds.find(r => r.round === currentRound)
                                .slide
                        }`
                    }

                    return {
                        value: slide,
                        label: label,
                    }
                })
                // .filter(slide => slide.label !== "Question")
                .filter(slide => {
                    if (
                        get().game?.teams?.length === 0 &&
                        (slide.label.includes("Select Category for Round") ||
                            slide.label.includes("Start of Round"))
                    ) {
                        return false
                    }
                    return true
                })
        )
        // if there are no teams in the game, we need to remove the "Select Category for Round" and "Start of Round" slides
    },

    setSlide: slide => {
        const newRound = get().getCurrentRoundBySlide(slide)
        get().updateGame({
            currentSlide: Number(slide),
            showAnswer: false,
            currentQuestion: 0,
            currentRound: newRound,
        })

        localStorage.setItem(
            "smt-game",
            JSON.stringify({
                ...get().game,
                currentSlide: Number(slide),
                showAnswer: false,
                currentQuestion: 0,
                currentRound: newRound,
            })
        )
    },

    currentSlideTitle: () => {
        const currentSlide = get().game?.currentSlide || 0
        return get().slides[currentSlide]
    },

    refreshGame: () => {
        const gameData = JSON.parse(localStorage.getItem("smt-game"))
        if (gameData) {
            set({ game: gameData })
            get().prepareScoreboard()
        }
    },

    // Method to load the game object from Firestore
    loadGame: async gameId => {
        set({ loading: true, error: null })
        try {
            const gameRef = doc(db, "games", gameId)
            const gameSnap = await getDoc(gameRef)
            if (gameSnap.exists()) {
                set({
                    game: { ...gameSnap.data(), id: gameId },
                    loading: false,
                })
                localStorage.setItem(
                    "smt-game",
                    JSON.stringify({ ...gameSnap.data(), id: gameId })
                )
                //  now get the matching template if it has one
                const gameData = gameSnap.data()
                const sortedRounds = gameData.rounds.sort(
                    (a, b) => a.round - b.round
                )
                set({
                    gameRounds: sortedRounds,
                    gameTeams: gameData.teams,
                    gameScores: gameData.teams.map(team => team.scores).flat(),
                })

                get().prepareScoreboard()
                if (gameData.template && gameData.template !== "") {
                    const templateRef = doc(db, "templates", gameData.template)
                    const templateSnap = await getDoc(templateRef)
                    if (templateSnap.exists()) {
                        set({ template: templateSnap.data() })
                    }
                } else {
                    set({ template: null })
                }
            } else {
                set({ error: "Game not found", loading: false })
            }
        } catch (error) {
            set({ error: error.message, loading: false })
        }
    },

    prepareScoreboard: async () => {
        const gameScoreboard = get().gameTeams.map(team => {
            const teamScores = team.scores
            const teamTotal = teamScores.reduce((acc, score) => {
                return acc + score.questions.filter(q => q.correct).length
            }, 0)
            const matchingTeam = useTeamStore
                .getState()
                .teams.find(t => t.id === team.team)
            return {
                team: team.team,
                displayName: team.displayName
                    ? team.displayName
                    : matchingTeam?.name,
                name: matchingTeam?.name,
                score: teamTotal,
                members: team.members?.length || 0,
            }
        })
        set({ scoreboard: gameScoreboard })
    },

    // Method to update the game document in Firestore
    updateGame: async updatedFields => {
        set({ loading: true, error: null })
        try {
            const gameId = get().game?.id
            const gameRef = gameId ? doc(db, "games", gameId) : null

            await updateDoc(gameRef, updatedFields)
            // Update local state after successfully updating in Firestore
            set(state => ({
                game: { ...state.game, ...updatedFields },
                loading: false,
            }))

            // Update local storage with the updated game data
            localStorage.setItem(
                "smt-game",
                JSON.stringify({ ...get().game, ...updatedFields })
            )
        } catch (error) {
            console.error("Error updating game", error)
            set({ error: error.message, loading: false })
        }
    },

    addTeamMate: (teamId, userUID) => {
        const updatedTeams = get().game.teams.map(team => {
            if (team.team === teamId) {
                return {
                    ...team,
                    members: team.members.concat(userUID),
                }
            }
            return team
        })

        get().updateGame({
            teams: updatedTeams,
        })

        set({ gameTeams: updatedTeams })

        get().prepareScoreboard()
    },
    removeTeamMate: (teamId, userUID) => {
        const updatedTeams = get().game.teams.map(team => {
            if (team.team === teamId) {
                return {
                    ...team,
                    members: team.members.filter(member => member !== userUID),
                }
            }
            return team
        })

        get().updateGame({
            teams: updatedTeams,
        })

        set({ gameTeams: updatedTeams })

        get().prepareScoreboard()
    },

    // Method to add a new team to the game
    addTeam: (teamID, displayName = "", userUID) => {
        // get current ROund
        const currentRound = get().getCurrentRound()
        const updatedTeams = get().game.teams.concat({
            team: teamID,
            displayName,
            members: userUID ? [userUID] : [],
            scores: get().game.rounds.map(round => ({
                round: round.round,
                questions: Array.from({
                    length: gameOptions.questionsPerRound[round.round - 1],
                }).map((_, i) => ({
                    question: i + 1,
                    correct: false,
                })),
                touched: round.round < currentRound,
            })),
        })

        get().updateGame({
            teams: updatedTeams,
        })

        set({ gameTeams: updatedTeams })

        get().prepareScoreboard()
    },

    // Method to update a team's display name
    changeTeamDisplayName: (teamId, newDisplayName) => {
        const updatedTeams = get().game.teams.map(team =>
            team.team === teamId
                ? { ...team, displayName: newDisplayName }
                : team
        )
        get().updateGame({
            teams: updatedTeams,
        })
        set({ gameTeams: updatedTeams })
        get().prepareScoreboard()
    },

    // Method to remove a team from the game
    removeTeam: teamId => {
        const updatedTeams = get().game.teams.filter(
            team => team.team !== teamId
        )
        get().updateGame({
            teams: updatedTeams,
        })
        set({ gameTeams: updatedTeams })
        get().prepareScoreboard()
    },

    // Method to score a round for a team
    scoreRound: (teamId, roundNumber, question, correct) => {
        const updatedTeams = get().game.teams.map(team => {
            if (team.team === teamId) {
                const updatedScores = team.scores.map(score => {
                    if (score.round === roundNumber) {
                        const updatedQuestions = score.questions.map(q =>
                            q.question === question ? { ...q, correct } : q
                        )
                        return { ...score, questions: updatedQuestions }
                    }
                    return score
                })
                return { ...team, scores: updatedScores }
            }
            return team
        })

        get().updateGame({
            teams: updatedTeams,
            lastUpdated: moment().format(),
        })

        set({ gameTeams: updatedTeams })
        get().prepareScoreboard()
    },

    setAllCorrect: (teamId, roundNumber) => {
        const updatedTeams = get().game.teams.map(team => {
            if (team.team === teamId) {
                const updatedScores = team.scores.map(score => {
                    if (score.round === roundNumber) {
                        const updatedQuestions = score.questions.map(q => ({
                            ...q,
                            correct: true,
                        }))
                        return { ...score, questions: updatedQuestions }
                    }
                    return score
                })
                return { ...team, scores: updatedScores }
            }
            return team
        })

        get().updateGame({
            teams: updatedTeams,
            lastUpdated: moment().format(),
        })

        set({ gameTeams: updatedTeams })
        get().prepareScoreboard()
    },

    setAllIncorrect: (teamId, roundNumber) => {
        const updatedTeams = get().game.teams.map(team => {
            if (team.team === teamId) {
                const updatedScores = team.scores.map(score => {
                    if (score.round === roundNumber) {
                        const updatedQuestions = score.questions.map(q => ({
                            ...q,
                            correct: false,
                        }))
                        return { ...score, questions: updatedQuestions }
                    }
                    return score
                })
                return { ...team, scores: updatedScores }
            }
            return team
        })

        get().updateGame({
            teams: updatedTeams,
            lastUpdated: moment().format(),
        })

        set({ gameTeams: updatedTeams })
        get().prepareScoreboard()
    },

    setScoreTouched: (teamId, roundNumber) => {
        const updatedTeams = get().game.teams.map(team => {
            if (team.team === teamId) {
                const updatedScores = team.scores.map(score => {
                    if (score.round === roundNumber) {
                        return { ...score, touched: true }
                    }
                    return score
                })
                return { ...team, scores: updatedScores }
            }
            return team
        })

        get().updateGame({
            teams: updatedTeams,
            lastUpdated: moment().format(),
        })

        set({ gameTeams: updatedTeams })
    },

    markAllScoresTouched: () => {
        const updatedTeams = get().game.teams.map(team => {
            const updatedScores = team.scores.map(score => ({
                ...score,
                touched: true,
            }))
            return { ...team, scores: updatedScores }
        })

        get().updateGame({
            teams: updatedTeams,
            lastUpdated: moment().format(),
        })

        set({ gameTeams: updatedTeams })
    },

    // Method to assign a category to a round
    assignCategory: (roundNumber, category, team) => {
        // Ensure game and rounds are available before proceeding
        const game = get().game

        if (!game || !game.rounds || game.rounds.length === 0) {
            console.error("Game or rounds data is missing or invalid.")
            return // Exit the function if game or rounds are not properly defined
        }

        // Safely map over rounds and assign the category to the matching round
        const updatedRounds = game.rounds.map(round => {
            // Ensure round is defined and has a valid round number
            if (!round || typeof round.round === "undefined") {
                console.error("Invalid round data:", round)
                return round // Skip invalid rounds, or handle them accordingly
            }

            // Assign category if the roundNumber matches
            return round.round === roundNumber
                ? { ...round, category, team }
                : round
        })

        // Ensure updatedRounds has been correctly updated before continuing
        if (!updatedRounds || updatedRounds.length === 0) {
            console.error("No valid rounds found to update.")
            return
        }

        // Update the game in Firestore and in the local state
        get().updateGame({ rounds: updatedRounds })

        // Update local state for gameRounds
        set({ gameRounds: updatedRounds })
    },

    finalizeScores: () => {
        const shouldFinalize = window.confirm(
            "Are you sure you want to finalize the scores?"
        )

        if (!shouldFinalize) return

        get().game.teams.forEach(team => {
            const totalScore = team.scores.reduce((acc, score) => {
                return acc + score.questions.filter(q => q.correct).length
            }, 0)
            addDoc(collection(db, "scores"), {
                date: get().game.date,
                team: team.team,
                score: totalScore,
                venue: get().game.venue,
                season: get().game.season,
                user: get().game.user,
                game: get().game.id,
            })
        })

        get().updateGame({ status: "finalized" })
    },

    nextSlide: () => {
        const currentSlide = get().game?.currentSlide || 0
        get().updateGame({
            currentSlide: currentSlide + 1,
            started: !get().game.started
                ? currentSlide >= 6
                : get().game.started,
        })
    },

    prevSlide: () => {
        const currentSlide = get().game?.currentSlide || 0
        get().updateGame({ currentSlide: currentSlide - 1 })
    },
    nextSlideTitle: () => {
        const currentSlide = get().game?.currentSlide || 0
        return get().slides[currentSlide + 1]
    },
    getSlideTitle: slide => {
        return get().slides[slide]
    },
    getCurrentRound: () => {
        const currentSlide = get().game?.currentSlide || 0
        const currentRound = get().rounds.find(
            (r, index, arr) =>
                r.slide <= currentSlide + 1 &&
                (arr[index + 1]?.slide > currentSlide + 1 ||
                    index === arr.length - 1)
        )?.round
        return currentRound
    },
    getCurrentRoundFromGame: game => {
        const currentSlide = game?.currentSlide || 0
        const currentRound = get().rounds.find(
            (r, index, arr) =>
                r.slide <= currentSlide + 1 &&
                (arr[index + 1]?.slide > currentSlide + 1 ||
                    index === arr.length - 1)
        )?.round
        return currentRound
    },
    getCurrentRoundBySlide: slideNum => {
        const slide = Number(slideNum)
        const currentRound =
            get().rounds.find(
                (r, index, arr) =>
                    r.slide <= slide + 1 &&
                    (arr[index + 1]?.slide > slide + 1 ||
                        index === arr.length - 1)
            )?.round || 0
        return currentRound
    },
    determineDisabled: () => {
        const game = get().game
        const currentRound = get().rounds.find(
            (r, index, arr) =>
                r.slide <= game.currentSlide + 1 &&
                (arr[index + 1]?.slide > game.currentSlide + 1 ||
                    index === arr.length - 1)
        )?.round

        const disabled =
            get().slides[game.currentSlide + 1] === "CategorySelection" &&
            game.teams.length === 0
                ? { disabled: true, text: "Add Teams First..." }
                : get().slides[game.currentSlide + 1] === "Category" &&
                  game.rounds[currentRound - 1]?.category === ""
                ? { disabled: true, text: "Select a Category First..." }
                : game.currentSlide === get().lastSlide() &&
                  game.status !== "finalized" &&
                  game.teams.some(team =>
                      team.scores.some(score => !score.touched)
                  )
                ? {
                      disabled: true,
                      text: "Score All Teams First...",
                  }
                : get().slides[game.currentSlide + 1] === "Scoreboard" &&
                  get().slides[game.currentSlide] !== "Answers" &&
                  game.teams.some(
                      team => team.scores[currentRound - 1].touched === false
                  ) && {
                      disabled: true,
                      text: "Score All Teams First...",
                  }
        return disabled
    },

    determineNextButtonText: () => {
        return get().determineDisabled() ? "Waiting..." : "Next"
    },
    findMembers: teamId => {
        return get().game?.teams?.find(team => team.team === teamId)?.members
    },
    findMemberDetails: async teamId => {
        const members = get().findMembers(teamId)
        if (!members || members.length === 0) {
            return []
        }
        const memberDetails = await Promise.all(
            members.map(async memberId => {
                const q = query(
                    collection(db, "users"),
                    where("uid", "==", memberId)
                )
                const querySnapshot = await getDocs(q)
                if (!querySnapshot.empty) {
                    const userData = querySnapshot.docs[0].data()
                    return {
                        uid: memberId,
                        email: userData.email,
                        name: userData.name,
                    }
                } else {
                    return {
                        uid: memberId,
                        email: null,
                        name: null,
                    }
                }
            })
        )
        return memberDetails
    },
}))

export default useOneGameStore
