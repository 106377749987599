import React, { useState, useEffect } from "react"
import moment from "moment"
import { Link } from "react-router-dom"

import useUserStore from "../../data/userStore"
import Modal from "../Modal/Modal"
import Game from "../../pages/Game/Game"

import homepageService from "../../data/homepageService"
import { getNextOccurrence } from "../../utils/dateUtils"
import useVenueStore from "../../data/venueStore"
import useSeasonStore from "../../data/seasonStore"
import { getFunctions, httpsCallable } from "firebase/functions"
import { SmallLoading, Loading } from "../Loading"
import VenuePage from "../../pages/VenuePage"

export default function UpcomingGames({
    currentUpcomingGame,
    setCurrentUpcomingGame,
    currentUpcomingGameType,
    setCurrentUpcomingGameType,
}) {
    const [upcomingGames, setUpComingGames] = useState([])
    const [upcomingGamesByVenue, setUpcomingGamesByVenue] = useState([])
    const [displayedGames, setDisplayedGames] = useState([])
    const [page, setPage] = useState(1)
    const [needsVenues, setNeedsVenues] = useState(false)
    const userLocation = useUserStore(state => state.userLocation)
    const setDiscount = useUserStore(state => state.setDiscount)
    const activeSeason = useSeasonStore.getState().activeSeason
    const [liveGame, setLiveGame] = useState(null)
    const currentUser = useUserStore(state => state.currentUser)
    const [closestVenues, setClosestVenues] = useState([])
    const venues = useVenueStore(state => state.venues)
    const hosts = useUserStore(state => state.hosts)
    const [loading, setLoading] = useState(true)
    const [currentVenue, setCurrentVenue] = useState(0)
    const [loadedUpcomingGames, setLoadedUpcomingGames] = useState(false)

    const functions = getFunctions()

    const tryLoadingUpcomingGames = async isMounted => {
        const getUpcomingGames = httpsCallable(functions, "getUpcomingGames")
        const somd = {
            lat: 38.4412807,
            lng: -76.7602544,
        }
        const data = await getUpcomingGames({
            location:
                userLocation && userLocation?.lat ? { ...userLocation } : somd,
        })
        if (isMounted) {
            setUpComingGames(data.data.upcomingGames)
            setClosestVenues(data.data.closestVenues)
            setNeedsVenues(!data.data.isAnyVenueWithin50Miles)
        }
    }

    useEffect(() => {
        setLoading(true)
        let isMounted = true

        tryLoadingUpcomingGames(isMounted)

        return () => {
            isMounted = false
        }
    }, [activeSeason, functions])

    useEffect(() => {
        if (userLocation) {
            const sortedGames = upcomingGames
                .map(game => {
                    if (
                        game.distance < 0.2 &&
                        game?.date === moment().format("YYYY-MM-DD") &&
                        game?.time &&
                        moment().isBetween(
                            moment(game?.time, "HH:mm").subtract(1, "hours"),
                            moment(game?.time, "HH:mm").add(1, "hours")
                        )
                    ) {
                        const liveGameURL = localStorage.getItem("ttl-game-url")
                        if (!liveGameURL) {
                            setLiveGame(
                                `/live-game/${game.id}/${game.template}`
                            )
                        }
                        game.live = true
                    } else {
                        game.live = false
                    }
                    game.host = hosts.find(host => host.uid === game.user)
                    return game
                })
                .filter(
                    game =>
                        game.date &&
                        moment(game.date).isSameOrAfter(moment(), "day")
                )
                .sort((a, b) => {
                    const dateA = moment(a.date)
                    const dateB = moment(b.date)
                    const timeA = moment(a.time, "HH:mm")
                    const timeB = moment(b.time, "HH:mm")
                    if (dateA.isSame(dateB)) {
                        if (timeA.isSame(timeB)) {
                            return a.distance - b.distance
                        }
                        return timeA - timeB
                    }
                    return dateA - dateB
                })

            setDisplayedGames(
                [
                    ...sortedGames,
                    closestVenues.map(venue => {
                        return { ...venue, venueData: venue }
                    }),
                ]
                    .flat()
                    .slice(0, 6)
            )

            setLoading(false)
        }
    }, [userLocation, upcomingGames, page])

    useEffect(() => {
        const interval = setInterval(() => {
            if (displayedGames.length > 0) {
                setLoadedUpcomingGames(true)
            } else {
                tryLoadingUpcomingGames()
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [displayedGames])

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop !==
            document.documentElement.offsetHeight
        )
            return
        setPage(prevPage => prevPage + 1)
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    const isCurrentlyLive = game => {
        return (
            game?.date === moment().format("YYYY-MM-DD") &&
            game?.time &&
            moment().isBetween(
                moment(game?.time, "HH:mm").subtract(15, "minutes"),
                moment(game?.time, "HH:mm").add(3, "hours")
            )
        )
    }

    return (
        <div className="home__upcoming-games">
            <h2>Upcoming Games</h2>
            {(loading || !loadedUpcomingGames) && (
                <div className="loading-location">
                    <SmallLoading />
                    <p>Getting your location...</p>
                    <p>
                        If you are still waiting after 15 seconds, kindly reload
                    </p>
                </div>
            )}
            {needsVenues &&
                currentUser?.role !== "host" &&
                currentUser?.role !== "admin" && (
                    <Link to="/host" className="become-host-button">
                        Become the first host in your area
                    </Link>
                )}
            {needsVenues &&
                (currentUser?.role === "host" ||
                    currentUser?.role === "admin") && (
                    <Link to="/host-dashboard" className="become-host-button">
                        Host your own local game!!!
                    </Link>
                )}
            {!loading && loadedUpcomingGames && (
                <div className="home__upcoming-games-list">
                    {displayedGames.map(game => (
                        <div
                            key={game.id}
                            className={`home__upcoming-game ${
                                game.date &&
                                moment(game.date).format("ll") ===
                                    moment().format("ll") &&
                                "home__upcoming-game-upcoming"
                            } ${game.live && "home__upcoming-game-now"} ${
                                game.day && "home__upcoming-game-no-date"
                            } ${
                                isCurrentlyLive(game) &&
                                "home__upcoming-game-currently-live"
                            }`}
                            style={{
                                backgroundImage: game.venueData.photo
                                    ? `url(${game.venueData.photo})`
                                    : `url('/images/venues/${game.venueData.id}.jpg')`,
                            }}
                            onClick={() => {
                                if (!game.live) {
                                    setCurrentUpcomingGame(() => game)
                                    setCurrentVenue(() => game.venueData.id)
                                    setCurrentUpcomingGameType(() => "venue")
                                } else {
                                    setCurrentUpcomingGame(() => game.id)
                                    setCurrentUpcomingGameType(() => "game")
                                }
                            }}
                        >
                            {game.date && (
                                <>
                                    <span className="home__upcoming-game-date">
                                        <span className="home__upcoming-game-date-month">
                                            {moment(game.date).format("MMM")}
                                        </span>
                                        <span className="home__upcoming-game-date-day">
                                            {game.date &&
                                                moment(game.date).format("DD")}
                                        </span>
                                        {game.day && (
                                            <span className="home__upcoming-game-date-day-of-week">
                                                {moment(
                                                    game.day,
                                                    "dddd"
                                                ).format("ddd")}
                                            </span>
                                        )}
                                    </span>

                                    <span className="home__upcoming-game-time-box">
                                        {game.time
                                            ? moment(game.time, "HH:mm").format(
                                                  "h:mm A"
                                              )
                                            : ""}
                                    </span>
                                </>
                            )}
                            {game.day && (
                                <div className="upcoming-game-no-date">
                                    <p>
                                        Typically {game.name} holds games on{" "}
                                        {game.day}s at{" "}
                                        {moment(game.time, "HH:mm").format(
                                            "h:mm A"
                                        )}
                                        . Reach out to the venue for more
                                        information.
                                    </p>
                                </div>
                            )}
                            <div className="home__upcoming-game-info">
                                <h3 className="home__upcoming-game-venue splash">
                                    {game.venueData.name}
                                </h3>
                                <div className="home__upcoming-game-bottom-line">
                                    <span className="home__upcoming-game-date">
                                        {game.venueData.city},{" "}
                                        {game.venueData.state}
                                    </span>
                                    <span className="home__upcoming-game-time">
                                        {game.distance.toFixed(1)} mi
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {/* <Link
                to="/schedule"
                className="home__view-more splash-bg splash-border"
            >
                Full Schedule
            </Link> */}

            {currentUpcomingGame !== 0 &&
                currentUpcomingGameType === "game" && (
                    <Modal
                        title="Game Details"
                        onClose={() => {
                            setCurrentUpcomingGame(0)
                            setCurrentUpcomingGameType("game")
                        }}
                        className="game-modal small-header"
                    >
                        <Game
                            id={currentUpcomingGame}
                            type={currentUpcomingGameType}
                            presentation="game"
                        />
                    </Modal>
                )}

            {currentUpcomingGame !== 0 &&
                currentUpcomingGameType === "venue" && (
                    <Modal
                        title=""
                        onClose={() => {
                            setCurrentUpcomingGame(0)
                            setCurrentVenue(0)
                            setCurrentUpcomingGameType("game")
                        }}
                        className="venue-modal black-text"
                    >
                        <VenuePage
                            id={currentVenue}
                            game={currentUpcomingGame}
                        />
                    </Modal>
                )}

            {liveGame && (
                <Modal
                    title="Live Game"
                    onClose={() => {
                        setLiveGame(null)
                    }}
                    className="live-game-modal"
                >
                    <h2 className="live-game-modal-title">
                        You're at a Live Game!
                    </h2>
                    <Link className="live-game-modal-button" to={liveGame}>
                        Join Now
                    </Link>
                </Modal>
            )}
        </div>
    )
}
