import moment from "moment"

import {
    MusicalNoteIcon,
    PhotoIcon,
    BeakerIcon,
} from "@heroicons/react/24/solid"
import {
    GiWorld,
    GiPerspectiveDiceSixFacesRandom,
    GiAncientColumns,
} from "react-icons/gi"
import { MdScoreboard } from "react-icons/md"
import { PiShootingStar, PiPopcorn } from "react-icons/pi"
import { IoIosBaseball } from "react-icons/io"
import { GiMagnifyingGlass } from "react-icons/gi"

export const gameOptions = {
    numberOfRoundsPerGame: 7,
    questionsPerRound: [8, 8, 8, 8, 8, 8, 8],
    legacyNumberOfRoundsPerGame: 7,
    legacyQuestionsPerRound: [6, 6, 10, 6, 6, 10, 6],
    legacyDate: moment("2024-06-17"),
}

export const isLegacyGame = game =>
    moment(game.date).isBefore(gameOptions.legacyDate)
export const formatDate = date =>
    moment(date?.toDate ? date.toDate() : date).format("dddd MM/DD/YYYY")

export const getNextOccurrence = day => {
    const targetDay = moment().day(day) // Day string like "Wednesday"
    if (targetDay.isBefore && targetDay?.isBefore(moment())) {
        return targetDay.add(1, "week")
    }
    return targetDay
}

export const rankings = [
    {
        name: "Games",
        label: "Games",
        icon: <GiPerspectiveDiceSixFacesRandom className="medal-icon" />,
        ratings: [5, 15, 60, 150, 220, 500],
        // bronze, silver, gold, platinum, diamond, master
    },
    {
        name: "Games Won",
        label: "Games Won",
        ratings: [1, 5, 25, 50, 100],
    },
    {
        name: "History",
        label: "History",
        icon: <GiAncientColumns className="medal-icon" />,
        ratings: [10, 50, 200, 500, 1000, 2000],
    },
    {
        name: "Science",
        label: "Science",
        icon: <BeakerIcon className="medal-icon" />,
        ratings: [10, 50, 200, 500, 1000, 2000],
    },
    {
        name: "Sports",
        label: "Sports",
        icon: <IoIosBaseball className="medal-icon" />,
        ratings: [10, 50, 200, 500, 1000, 2000],
    },
    {
        name: "Places",
        label: "Places",
        icon: <GiWorld className="medal-icon" />,
        ratings: [10, 50, 200, 500, 1000, 2000],
    },
    {
        name: "Pictures",
        label: "Pictures",
        icon: <PhotoIcon className="medal-icon" />,
        ratings: [10, 50, 200, 500, 1000, 2000],
    },
    {
        name: "Mystery",
        label: "Mystery",
        icon: <GiMagnifyingGlass className="medal-icon" />,
        ratings: [10, 50, 200, 500, 1000, 2000],
    },
    {
        name: "Music",
        label: "Music",
        icon: <MusicalNoteIcon className="medal-icon" />,
        ratings: [10, 50, 200, 500, 1000, 2000],
    },
    {
        name: "Movies",
        label: "Movies",
        icon: <PiPopcorn className="medal-icon" />,
        ratings: [10, 50, 200, 500, 1000, 2000],
    },
    {
        name: "Pop Culture",
        label: "Pop Culture",
        icon: <PiShootingStar className="medal-icon" />,
        ratings: [10, 50, 200, 500, 1000, 2000],
    },
    {
        name: "Score",
        label: "Score",
        icon: <MdScoreboard className="medal-icon" />,
        ratings: [50, 300, 1200, 4000, 10000, 20000],
    },
]
