import React, { useState } from "react"
import moment from "moment"
import { FaPencilAlt, FaRegCalendarPlus } from "react-icons/fa"
import { Link } from "react-router-dom"

import { GiPlayButton } from "react-icons/gi"

import useVenueStore from "../../data/venueStore"

import "./AgendaView.css"

export default function AgendaView({
    games,
    setGames,
    handleEditGame,
    showLiveScoring,
    showVenueWizard,
    gameCredits,
}) {
    const [activeTab, setActiveTab] = useState("upcoming")
    const venues = useVenueStore(state => state.venues)

    const upcomingGames = games
        .filter(game => moment(game.date).isSameOrAfter(moment(), "day"))
        .sort((a, b) => moment(a.date) - moment(b.date))

    const pastGames = games
        .filter(game => moment(game.date).isBefore(moment(), "day"))
        .sort((a, b) => moment(b.date) - moment(a.date))

    const ghostGames = []
    const venueSet = [
        ...new Set(
            upcomingGames.map(game => {
                return { venue: game.venue, date: game.date, time: game.time }
            })
        ),
    ]
    //  ghost game logic that needs to be rewritten - rewrite to only be the next week
    //  venueSet.forEach(venue => {
    //      for (let i = 1; i <= 4; i++) {
    //          const nextDate = moment(venue.date)
    //              .add(i, "weeks")
    //              .format("YYYY-MM-DD")
    //          if (
    //              !upcomingGames.some(
    //                  game =>
    //                      game.venue === venue.venue &&
    //                      moment(game.date).format("YYYY-MM-DD") === nextDate
    //              )
    //          ) {
    //              ghostGames.push({
    //                  id: `ghost-${venue.venue}-${nextDate}`,
    //                  name: `Next Game for ${
    //                      venues.find(v => v.id === venue.venue).name
    //                  }`,
    //                  date: nextDate,
    //                  venue: venue.venue,
    //                  time: venue.time,
    //              })
    //          }
    //      }
    //  })

    const groupGamesByDate = games => {
        const grouped = {}
        games.forEach(game => {
            const date = moment(game.date).format("YYYY-MM-DD")
            if (!grouped[date]) {
                grouped[date] = []
            }
            grouped[date].push(game)
        })
        return grouped
    }

    const upcomingGrouped = groupGamesByDate([...upcomingGames, ...ghostGames])
    const pastGrouped = groupGamesByDate(pastGames)

    return (
        <div className="agenda-view">
            <ul className="tabs black-text">
                <li
                    className={activeTab === "upcoming" ? "active" : ""}
                    onClick={() => setActiveTab("upcoming")}
                >
                    Upcoming Games
                </li>
                <li
                    className={activeTab === "past" ? "active" : ""}
                    onClick={() => setActiveTab("past")}
                >
                    Past Games
                </li>
            </ul>
            <div className="tab-content black-text">
                {activeTab === "upcoming" && (
                    <div className="upcoming-games">
                        {Object.keys(upcomingGrouped).length === 0 && (
                            <div className="no-upcoming-games">
                                <h2 className="black-text no-upcoming-games-text">
                                    No upcoming games!
                                </h2>

                                <button
                                    className="schedule-game-without-date"
                                    onClick={() => showVenueWizard()}
                                    disabled={gameCredits < 1}
                                >
                                    <FaRegCalendarPlus />
                                    <span>Schedule Game</span>
                                </button>
                            </div>
                        )}

                        <ul>
                            {Object.keys(upcomingGrouped).map(date => (
                                <li key={date}>
                                    <span className="date-header">
                                        <span className="date-day-number">
                                            {moment(date).format("D")}
                                        </span>
                                        <span className="date-day-month-name">
                                            <span className="date-day-month-name-top">
                                                {moment(date).format("MMM")},{" "}
                                                {moment(date).format("YYYY")}
                                            </span>
                                            <span className="date-day-month-name-bottom">
                                                {moment(date).format("ddd")}
                                            </span>
                                        </span>
                                    </span>
                                    <ul className="games-list">
                                        {upcomingGrouped[date].map(game => (
                                            <li
                                                key={game.id}
                                                className={
                                                    game.id.startsWith("ghost")
                                                        ? "ghost"
                                                        : ""
                                                }
                                            >
                                                <span
                                                    className={`game-status game-status-${
                                                        game.id.startsWith(
                                                            "ghost"
                                                        )
                                                            ? "ghost"
                                                            : game.status.toLowerCase()
                                                    }`}
                                                >
                                                    {game.status}
                                                </span>
                                                <span className="start-time">
                                                    {game.time
                                                        ? moment(
                                                              game.time,
                                                              "HH:mm"
                                                          ).format("h:mm A")
                                                        : "n/a"}
                                                </span>
                                                <span className="venue-details">
                                                    <span className="venue-name">
                                                        {game.id.startsWith(
                                                            "ghost"
                                                        )
                                                            ? game.name
                                                            : venues.find(
                                                                  v =>
                                                                      v.id ===
                                                                      game.venue
                                                              )?.name ||
                                                              "Retired Venue"}
                                                    </span>
                                                    {!game.id.startsWith(
                                                        "ghost"
                                                    ) && (
                                                        <span className="team-count">
                                                            Teams:{" "}
                                                            {game.teams
                                                                ? game.teams
                                                                      .length
                                                                : 0}
                                                        </span>
                                                    )}
                                                </span>
                                                {game.id.startsWith("ghost") ? (
                                                    <button
                                                        className="schedule-game-without-date"
                                                        onClick={() =>
                                                            showVenueWizard(
                                                                game.date,
                                                                game.venue,
                                                                game.time
                                                            )
                                                        }
                                                        disabled={
                                                            gameCredits < 1
                                                        }
                                                    >
                                                        <FaRegCalendarPlus />
                                                        <span>
                                                            Schedule Game
                                                        </span>
                                                    </button>
                                                ) : (
                                                    <span className="game-buttons">
                                                        {/* <button
                                                            className="launch-todays-game-button black-text"
                                                            onClick={() =>
                                                                showLiveScoring(
                                                                    game.id
                                                                )
                                                            }
                                                        >
                                                            <GiPlayButton />
                                                            <span>
                                                                Launch Scoring
                                                            </span>
                                                        </button> */}
                                                        <Link
                                                            to={`/game/${game.id}`}
                                                            className="game-page-link"
                                                        >
                                                            <button>
                                                                Game Page
                                                            </button>
                                                        </Link>
                                                        <span
                                                            onClick={() =>
                                                                handleEditGame(
                                                                    game
                                                                )
                                                            }
                                                            className="edit-game"
                                                        >
                                                            <FaPencilAlt />
                                                        </span>
                                                    </span>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {activeTab === "past" && (
                    <div className="past-games">
                        <ul className="past-games-list">
                            {Object.keys(pastGrouped).map(date => (
                                <li key={date} className="past-games-list-item">
                                    <span className="date-header">
                                        <span className="date-day-number">
                                            {moment(date).format("D")}
                                        </span>
                                        <span className="date-day-month-name">
                                            <span className="date-day-month-name-top">
                                                {moment(date).format("MMM")},{" "}
                                                {moment(date).format("YYYY")}
                                            </span>
                                            <span className="date-day-month-name-bottom">
                                                {moment(date).format("ddd")}
                                            </span>
                                        </span>
                                    </span>
                                    <ul className="games-list">
                                        {pastGrouped[date].map(game => (
                                            <li
                                                key={game.id}
                                                className="past-game"
                                            >
                                                <span
                                                    className={`game-status game-status-${game.status.toLowerCase()}`}
                                                >
                                                    {game.status}
                                                </span>
                                                <span className="start-time">
                                                    {game.time
                                                        ? moment(
                                                              game.time,
                                                              "HH:mm"
                                                          ).format("h:mm A")
                                                        : "n/a"}
                                                </span>
                                                <span className="venue-details">
                                                    <span className="venue-name">
                                                        {venues.find(
                                                            v =>
                                                                v.id ===
                                                                game.venue
                                                        )?.name ||
                                                            "Retired Venue"}
                                                    </span>
                                                    <span className="team-count">
                                                        Teams:{" "}
                                                        {game.teams
                                                            ? game.teams.length
                                                            : 0}
                                                    </span>
                                                </span>
                                                <span className="game-buttons">
                                                    <Link
                                                        to={`/game/${game.id}`}
                                                        className="game-page-link"
                                                    >
                                                        <button>
                                                            Game Page
                                                        </button>
                                                    </Link>
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )
}
